import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS, mobileAndTabletCSS } from '@measures/responsive'
import config from '@config'

import { WidgetKind } from '@utils/registry'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { TeaserBiteAPIProps } from '@widgets/TeaserBite'
import { TrackingFnType } from '@hooks/useTracking'

import useViewportType from '@hooks/useViewport/useViewportType'
import useTracking from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import useBodyScroll from '@hooks/useBodyScroll'
import useURLHashParam from '@hooks/useURLHashParam'
import InvertedColorsContext from '@contexts/invertedColors'
import SectionHeader from '@components/SectionHeader'
import Swiper, { ArrowsVisibilityType, SwiperClass } from '@components/Swiper'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import JSONRenderer from '@components/JSONRenderer'

import FullScreenVideoPortal from '@components/Video/BlickBites/FullScreenVideoPortal'

const {
  blickBites: { urlHashParam },
} = config

export interface BlickBitesItem extends TeaserBiteAPIProps {
  kind: WidgetKind
}

export interface BlickBitesData {
  content: BlickBitesItem[]
  previousCursor?: number
}

export interface BlickBitesWidgetAPIProps {
  title: string
  accentColor: string
  items: BlickBitesItem[]
}

interface FadeGradientProps {
  isHidden: boolean
  itemsCount: number
  position: 'left' | 'right'
}

const BlickBitesWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      spacing: { spacing32, spacing56 },

      measures: { stretchBackground },
    },
  }) => css`
    background-color: ${primary01Color};

    ${stretchBackground()};

    padding-top: ${spacing32};
    padding-bottom: ${spacing32};

    ${desktopCSS(css`
      padding: ${spacing56};
    `)}
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{ itemsCount: number }>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 2 &&
    mobileAndTabletCSS(css`
      display: none;
    `)}

    ${itemsCount <= 3 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const SwiperWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing32 },
      measures: { outerPadding },
    },
  }) => css`
    position: relative;
    margin-top: ${spacing16};

    ${mobileCSS(css`
      margin-right: -${outerPadding.mobile};
    `)}

    ${desktopCSS(css`
      margin-top: ${spacing32};
    `)}
  `}
`

const FadeGradient = styled.div<FadeGradientProps>`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
    },
    isHidden,
    itemsCount,
    position,
  }) => css`
    width: 40px;
    height: 100%;

    position: absolute;
    z-index: 1;
    top: 0;

    pointer-events: none;

    transition: opacity 400ms ease-in-out;

    ${isHidden &&
    css`
      opacity: 0;
    `}

    ${desktopCSS(css`
      width: 80px;
    `)};

    ${position === 'left' &&
    css`
      left: 0;
      background: linear-gradient(
        270deg,
        rgba(17, 17, 17, 0) 0%,
        ${primary01} 100%
      );
    `};

    ${position === 'right' &&
    css`
      right: 0;
      background: linear-gradient(
        270deg,
        ${primary01} 0%,
        rgba(17, 17, 17, 0) 100%
      );
    `};

    ${itemsCount <= 2 &&
    css`
      ${mobileAndTabletCSS(css`
        display: none;
      `)}
    `};

    ${itemsCount <= 3 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    > .swiper-items-container {
      grid-gap: ${spacing16};

      ${desktopCSS(css`
        grid-gap: ${spacing24};
      `)}
    }
  `}
`

const SwiperSlide = styled.div`
  ${({
    theme: {
      measures: { outerPadding },
    },
  }) => css`
    width: 220px;

    ${mobileCSS(css`
      width: 150px;

      &:last-child {
        margin-right: ${outerPadding.mobile};
        scroll-margin-right: ${outerPadding.mobile};
      }
    `)}
  `}
`

const BlickBites: FunctionComponent<BlickBitesWidgetAPIProps> = ({
  title,
  accentColor,
  items,
}) => {
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'

  const swiperRef = useRef<SwiperClass | null>(null)
  const isBodyScrollLocked = useRef<boolean>(false)

  const [showFullscreen, setShowFullscreen] = useState<boolean>(false)

  const [activeBlickBiteIndex, setActiveBlickBiteIndex] = useState<number>(0)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>('next')

  const shouldHideLeftGradient = useMemo(
    () => ['none', 'next', 'both'].includes(arrowsVisibility || ''),
    [arrowsVisibility]
  )

  const shouldHideRightGradient = useMemo(
    () => ['none', 'prev'].includes(arrowsVisibility || ''),
    [arrowsVisibility]
  )

  const URLHashBidValue = useURLHashParam(urlHashParam)
  const { lockBodyScroll, unlockBodyScroll } = useBodyScroll()

  useEffect(() => {
    if (URLHashBidValue) {
      setShowFullscreen(true)
    }
  }, [URLHashBidValue, items])

  useEffect(() => {
    return () => {
      if (isBodyScrollLocked.current) {
        unlockBodyScroll()
        isBodyScrollLocked.current = false
      }
    }
  }, [unlockBodyScroll])

  useEffect(() => {
    if (showFullscreen) {
      lockBodyScroll()
      isBodyScrollLocked.current = true
    } else {
      if (isBodyScrollLocked.current) {
        unlockBodyScroll()
        isBodyScrollLocked.current = false
      }
    }
  }, [lockBodyScroll, unlockBodyScroll, showFullscreen])

  const blickBitesItemClickHandler = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: 'blick_bites_widget',
    }),
    []
  )

  const blickBitesWidgetImpressionHandler = useCallback<TrackingFnType>(
    () => ({
      event: 'element_impression',
      element: 'blick_bites_widget',
    }),
    []
  )

  const trackItemClick = useTracking(blickBitesItemClickHandler)
  const trackWidgetImpression = useTracking(blickBitesWidgetImpressionHandler)

  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackWidgetImpression,
  })

  const handleOnItemClick = useCallback<
    (_id: string, indexPosition: number) => void
  >(
    (_id, indexPosition) => {
      setActiveBlickBiteIndex(indexPosition || 0)
      setShowFullscreen(true)
      trackItemClick()
    },
    [trackItemClick]
  )

  const handleOnClose = useCallback(() => {
    setShowFullscreen(false)
  }, [])

  const onInit = useCallback(
    (swiper: any) => {
      swiperRef.current = swiper
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  const itemsWithOnClick = useMemo<
    (BlickBitesItem & {
      onClick: () => void
    })[]
  >(
    () =>
      items?.map((swiperData, index) => {
        return {
          onClick: () => handleOnItemClick(swiperData?.articleId, index),
          ...swiperData,
        }
      }) || [],
    [items, handleOnItemClick]
  )

  return (
    <InvertedColorsContext.Provider value={true}>
      <BlickBitesWrapper ref={viewportRef}>
        <SectionHeader title={title} accentColor={accentColor}>
          <StyledNavigationArrows
            swiperRef={swiperRef}
            arrowsVisibility={arrowsVisibility}
            itemsCount={items?.length ?? 0}
          />
        </SectionHeader>
        <SwiperWrapper>
          <StyledSwiper
            spaceBetween={16}
            spaceTopBottom={0}
            slidesPerGroup={isDesktop ? 3 : 1}
            initialSlide={0}
            onInit={onInit}
            onArrowsVisibility={onArrowsVisibility}
            slides={itemsWithOnClick?.map(({ onClick, ...item }) => (
              <SwiperSlide key={item.articleId} onClick={onClick}>
                <JSONRenderer>{item}</JSONRenderer>
              </SwiperSlide>
            ))}
          />
          <FadeGradient
            isHidden={shouldHideLeftGradient && shouldHideRightGradient}
            position={shouldHideLeftGradient ? 'right' : 'left'}
            itemsCount={items?.length ?? 0}
          />
        </SwiperWrapper>
        {showFullscreen ? (
          <FullScreenVideoPortal
            data={items}
            biteId={URLHashBidValue}
            activeIndex={activeBlickBiteIndex}
            onClose={handleOnClose}
          />
        ) : null}
      </BlickBitesWrapper>
    </InvertedColorsContext.Provider>
  )
}

const widget = {
  kind: ['blick-bites'],
  component: BlickBites,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
