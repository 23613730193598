import { FunctionComponent, memo, isValidElement } from 'react'
import nanoid from '@utils/random'
import isEqual from 'fast-deep-equal'
import { getFromRegistry } from '@utils/registry'

const isStorybook = process.env.STORYBOOK

export interface JSONRendererProps {
  children: any | any[]
}

const JSONRenderer: FunctionComponent<JSONRendererProps> = ({ children }) => {
  if (Array.isArray(children)) {
    if (children.length === 0) {
      return null
    } else if (children.length === 1) {
      try {
        const { kind, ...restOfProps } = children[0]
        const Component = getFromRegistry(kind)

        if (!Component) {
          return null
        }

        return <Component {...restOfProps} />
      } catch (err) {
        // component is not an object
        return null
      }
    } else {
      return (
        <>
          {children.map((childData: any) => {
            try {
              const { kind, ...restOfProps } = childData
              const Component = getFromRegistry(kind)

              if (!Component) {
                return null
              }

              return <Component key={nanoid()} {...restOfProps} />
            } catch (err) {
              // component is not an object
              return null
            }
          })}
        </>
      )
    }
  } else {
    try {
      const { kind, ...restOfProps } = children

      const Component = getFromRegistry(kind)

      if (!Component) {
        return null
      }

      return <Component {...restOfProps} />
    } catch (err) {
      // component is not an object
      return null
    }
  }
}

const StorybookOnlyJSONRenderer: FunctionComponent<JSONRendererProps> = (
  props: JSONRendererProps
) =>
  (Array.isArray(props.children) && isValidElement(props.children[0])) ||
  isValidElement(props.children) ? (
    <>{props.children}</>
  ) : (
    <MemoizedJSONRenderer {...props} />
  )

const StorybookOnlyMemoizedJSONRenderer = memo(
  StorybookOnlyJSONRenderer,
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
)

StorybookOnlyMemoizedJSONRenderer.displayName =
  'StorybookOnlyMemoizedJSONRenderer'

const MemoizedJSONRenderer = memo(JSONRenderer, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
)

MemoizedJSONRenderer.displayName = 'MemoizedJSONRenderer'

export default isStorybook
  ? StorybookOnlyMemoizedJSONRenderer
  : MemoizedJSONRenderer
